import { CommonMargin } from 'components/_layout/CommonMargin';
import { Section } from 'components/_layout/Section/Section';
import SEO from 'components/_layout/Seo';
import * as Common from 'components/_universal/Common';
import React from 'react';
import { OfferWithDescription, RelativeSection } from 'sections/Offer/Offer.styled';
import { staffAugmentationData } from 'sections/Offer/offerData';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';
import { TrustedBySection } from 'sections/_universal/TrustedBySection';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { OurOfferSection } from 'sections/_universal/OurOfferSection';
import styled from 'styled-components';

export default () => {
  const isMd = useBreakpoint('md');

  return (
    <>
      <SEO
        title="Staff augmentation"
        meta={[
          {
            name: 'keywords',
            content: 'managed teams, outsourcing, US offshore software development, development for startups',
          },
        ]}
        description={
          'Need extra hands for a project or want to expand your capabilities quickly? Our staff augmentation services give you access to top talent exactly when you need it. Whether it’s for short-term projects or long-term growth, we provide skilled professionals to seamlessly integrate with your team, helping you achieve your goals faster and more efficiently. Looking for the right fit? Look no further!'
        }
      />

      <CommonMargin>
        <RelativeSection title="Staff augmentation" variant="secondary" id="Staff augmentation" isMobile={isMd}>
          <HeaderSection>
            Need extra hands for a project or want to expand your capabilities quickly? Our staff augmentation services
            give you access to top talent exactly when you need it. Whether it’s for short-term projects or long-term
            growth, we provide skilled professionals to seamlessly integrate with your team, helping you achieve your
            goals faster and more efficiently. Looking for the right fit? Look no further!
          </HeaderSection>
        </RelativeSection>
        <Section>
          <Common.Div flex={'column'} gap={isMd ? '0' : '140px'}>
            {staffAugmentationData.map(({ imgSrc, title, desc, descSecondary, href }, index) => (
              <OfferWithDescription
                alignLeft={isMd || index % 2 === 0}
                imgSrc={imgSrc}
                title={title}
                desc={desc}
                href={href}
                descSecondary={descSecondary}
                isMd={isMd}
                key={title}
              />
            ))}
          </Common.Div>
        </Section>

        <OurOfferSection />
        <TakeThisStepSection />
        <TrustedBySection />
      </CommonMargin>
    </>
  );
};

const HeaderSection = styled.div`
  margin-top: 40px;
  max-width: 900px;
  font-size: 20px;
  line-height: 1.65;
  font-weight: 400;
`;
